<template>
  <v-container>
    <Navbar v-bind:json="navigation" :corpId="corpId"/>
    <Login v-if="!user" />
    <Setup v-if="user" :corpId="corpId"/>
  </v-container>
</template>

<script>
import Navbar from '../components/Navbar'
import Login from "../components/Login.vue"
import Setup from "../components/Setup.vue"

import * as firebase from 'firebase/app';
import 'firebase/auth';
export default {
  name: 'modify',
  components: {
    Login,
    Setup,
    Navbar
  },
  data () {
    return {
      user: null,
      corpId: "",
      selectedPage: null,
      navigation: [
        { "title": "Home",
          "pageId": "home"
        },
        { "title": "Login",
          "pageId": "login",
          "loggedIn": false
        },
        { "title": "Switch Corp",
          "pageId": "switchCorp",
          "loggedIn": true
        },
        { "title": "Logout",
          "pageId": "logout",
          "loggedIn": true
        }
      ]
    }
  },
  watch: {
      // whenever question changes, this function will run
      selectedPage: function (newPage, oldPage) {
        console.log("page changed "+newPage);
        if (newPage == "logout") {
          this.logout();
        }
        if (newPage == "switchCorp") {
          this.corpId = "";
          this.selectedPage = null;
        }
      }
  },
  methods: {
    logout: function() {
      console.log("Initiate logout");
      firebase.auth().signOut().then(
        () => {
          this.user = null;
          this.corpId = "";
        }
    )}
  },
  created() {
    if(firebase.auth().currentUser) {
      this.user = firebase.auth().currentUser.email
//      this.$emit('update-user',this.currentUser)
    } else {
      console.log("User must login");
    }
  }
}
</script>
