import Vue from "vue";
import Vuetify from 'vuetify'
//import vuetify from './plugins/vuetify' // path to vuetify export

import App from "./App.vue";
import Router from "vue-router";
import index from "./router/index";
import route1 from "./router/route1";
import './components/firebaseInit'
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
//import 'material-design-icons'

import * as VueGoogleMaps from 'vue2-google-maps'
import { library } from '@fortawesome/fontawesome-svg-core'
//import { brands } from '@fortawesome/free-brands-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
//import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//import Sortable from 'vue-sortable'
import db from './components/firebaseInit'

library.add(faFacebookSquare)
library.add(faLinkedin)
//library.add(faEnvelope)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAuEoJy4BGGB5JsMfq5DZypQn_d4QHVTeA',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // ORAIzaSyAuEoJy4BGGB5JsMfq5DZypQn_d4QHVTeA: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

// Vue.use(Sortable)

Vue.use(Vuetify)


Vue.filter('toCurrency', function (value) {
	let numValue = Number(value);
    if (typeof numValue !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });
    return formatter.format(numValue);
});

Vue.config.productionTip = false

const host = window.location.host;
const protocol = window.location.protocol;

console.log("host: ",host);
console.log("protocol: ",protocol);

let defaultCorp = "";
let defaultHost = "localhost";

//let localhost = false;
if (host.substr(0,10)==="localhost:" || host === "judychu.ca") {
  console.log("Local Host or Website");
//  localhost = true;
  defaultHost = "judychu.ca";
}

const router = () => {
  let routes;
//  if (parts.length === (domainLength - 1) || parts[0] === 'www') {
if (defaultHost) {
    routes = route1;
  } else {
    routes = index;
  }
/*  } else if (parts[0] === 'route1') {
    routes = route1;
  } else if (parts[0] === 'route2') {
    routes = route2;
  } else {
    // If you want to do something else just comment the line below
    routes = index;
  } */
  return routes;
};

console.log("defaultHost: ",defaultHost);

if (defaultHost && defaultHost !== "localhost") {
  var ref = db.collection("hosts").doc(defaultHost)
  ref.get()
  .then(doc => {
    if (doc.exists && doc.data()) {
//          console.log("Data = ",doc.data());
        defaultCorp = doc.data().corp;
//        if (host.substr(0,10)==="localhost:") {
//          window.location = "http://"+host+"/#/home/"+defaultCorp;
//        } else {
          console.log("new location: ",protocol+"//"+host+"/#/home/"+defaultCorp);
          window.location = protocol+"//"+host+"/#/home/"+defaultCorp;
//        }
    } else {
        // doc.data() will be undefined in this case
        console.log("No such corp!",defaultCorp);
    }
  })
  .catch(error => {
    console.log("ref error",error)
  })
}

let app = new Vue({
			  el: '#app',
			  router: router(),
			  components: { App },
			  template: '<App/>'
			})
