<template>
	<div data-app>
	<v-container fluid>
		<v-layout>
			<v-flex xs6>
				<img :src="imageUrl" height="150" v-if="imageUrl && uploadProgress>'50'"/></br>
				<progress v-if="displayProgress" :value="uploadProgress" max="100">{{uploadProgress}}%</progress>
				<v-text-field label="Select Image" @click='pickFile' prepend-icon='attach_file'></v-text-field>
				<input
					type="file"
					style="display: none"
					ref="image"
					accept="image/*"
					@change="onFilePicked"
				>
			</v-flex>
		</v-layout>
	</v-container>
	</div>
</template>
<script>
	import * as firebase from 'firebase/app';
	import 'firebase/storage';
	import db from '../components/firebaseInit';
	export default {
		name: 'uploader',
		methods: {
			pickFile() {
	            this.$refs.image.click ()
	              },
  		onFilePicked (e) {
  			const files = e.target.files
  			if(files[0] !== undefined) {
  				let imageName = files[0].name
  				if(imageName.lastIndexOf('.') <= 0) {
  					return
  				}
  				const fr = new FileReader ()
  				fr.readAsDataURL(files[0])
  				fr.addEventListener('load', () => {
 					this.imageUrl = fr.result
  					let imageFile = files[0] // this is an image file that can be sent to server...
						this.upload(imageFile);
  				})
  			} else {
//  				this.imageName = ''
//  				this.imageFile = ''
  				this.imageUrl = ''
  			}
			},
			addFile(file) {
				if (this.images.indexOf(file) === -1) {
					this.images.push(file);
					let ref = db.collection("corps").doc(this.corpId);
					let response = ref.update({
						images: this.images
					})
					.then()
					.catch(function(error) {
					// The document probably doesn't exist.
						console.error("Error updating document: ", error);
					});
				}
				this.$emit('addedImage',file);
			},
			upload(file) {
				this.displayProgress = true;
				let self = this;

	      let storageRef = firebase.storage().ref(this.corpId + '/' + file.name);
	      let task = storageRef.put(file);
	      task.on('state_changed',
	        function progress(snapshot) {
	          let percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
	          self.uploadProgress = percentage;
	        },
	        function error(err) {
						console.log("error: ",err)
	        },
	        function complete() {
//						this.displayImage = true;
						console.log("Image Uploaded: "+file.name)
						self.addFile(file.name);
						self.displayProgress = false;
	        }

	      )
	    }

		},
		props: {
	    images: {
				type: Array,
				required: true
			},
			corpId: {
				type: String,
				required: true
			}
	  },
		data() {
			return {
				isLoggedIn: false,
				currentUser: false,
	      imageUrl: '',
				displayProgress: false,
				uploadProgress: 0
			}
		}
	}
</script>

<style scoped>
.email { padding-right: 10px; }
</style>
