<template>
  <v-container class="text-xs-center">
    <v-card flat>
      <v-card-title primary-title>
        <h4>Login</h4>
      </v-card-title>
      <v-form>
          <v-text-field
            prepend-icon="mail"
            v-model='email'
            label='Email'
            :rules='emailRules'
            required>
          </v-text-field>
          <v-text-field
            prepend-icon="lock"
            v-model='password'
            label='Password'
            :rules='loginRules'
            type="password"
            required>
          </v-text-field>
        <v-card-actions>
          <v-btn primary large block v-on:click="login">Login</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>

import * as firebase from 'firebase/app';
import 'firebase/auth';

export default {
  name: 'login',
  data () {
    return {
	    email: "",
	    password: "",
      loginRules: [v => !!v || "The password is required"],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ]
      }
    },
  mounted: function() {
      this.showForm = true;
  },
  methods: {
	  login: function(e) {
	  		firebase.auth().signInWithEmailAndPassword(this.email, this.password)
	  		.then(
	  			resp => {
		  		  alert("You are logged in as " + resp.user.email);
            this.$parent.user = resp.user.email;
		  		},
		  		err => {
		  			alert(err.message);
            this.$parent.user = null;
		  		})
	  		e.preventDefault();
	  }
  }
}
</script>
