<template>
  <div id="app">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.icon {
  border-radius: 50%;
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  }
.event {
  background-color: lightgray;
  margin: 10px;
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  }
  </style>
