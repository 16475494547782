<template>
  <v-container fluid grid-list-md text-xs-center>
    <Page v-model="json" :corpId="corpId" />
    <v-layout row wrap>
      <v-flex v-if="contacts.colour" xs12>
        <IconRow :facebook="contacts.facebook" :linkedIn="contacts.linkedIn" :email="contacts.email" :colour="contacts.colour" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
//import * as firebase from 'firebase/app'
//import 'firebase/auth';
//import 'firebase/firestore';
import db from '../components/firebaseInit'
import Page from '../components/Page.vue'
import IconRow from '../components/IconRow.vue'

export default {
  name: 'homePage',
  components: {
    Page,
    IconRow
  },
  props: {
    page: {
      type:String,
      required: true
    },
    corpId: {
      type:String,
      required: true
    },
    contacts: {
      type:Object,
      required: true
    }
  },
  data() {
    return {
      json: { "content":
          { "title":"Loading",
          "columns":[
            { 0: { "items":[ { 0: {} } ] } }
            ],
            "header":{},
            "footer":{}
          }
        },
      title: null,
      pageLayout: null
    }
  },
  computed: {
    pageTitle: function() {
      if (this.json && this.json.title) {
        document.title = this.json.title;
        return this.json.title;
      }
    },
    mailto: function() {
      return "mailto:"+this.contacts.email;
    }
  },
  watch: {
    // whenever question changes, this function will run
    page: function (newPage, oldPage) {
//			console.log("page changed "+newPage);
      if (newPage !== oldPage) {
			     this.fetchData(newPage);
      }
		}
  },
  methods: {
    fetchData(page) {
      var ref = db.collection("pages").doc(page)
      ref.get()
      .then(doc => {
        if (doc.exists && doc.data()) {
//            console.log("Data = ",doc.data());
            this.json=doc.data();
            this.pageLayout = this.json.layout;
        } else {
            // doc.data() will be undefined in this case
            console.log("No such page! "+page);
        }
      })
      .catch(error => {
        console.log("ref error",error)
      })
    }
  },
  created() {
    this.fetchData(this.page);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
