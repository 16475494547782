import Vue from 'vue';
import Router from 'vue-router';
import Home from "../views/Home.vue";
import Root from "../views/Root.vue";
import Modify from "../views/Modify.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "root",
      component: Root
    },
    {
      path: "/home/:corpId",
      name: "home",
      component: Home
    },
    {
      path: "/portal",
      name: "modify",
      component: Modify
    }
  ]
//  mode: 'history'
});
