<template>
  <div>
    <GoogleMap />
  </div>
</template>

<script>
import GoogleMap from "../components/GoogleMap.vue";

export default {
  name: "map",
  components: {
    GoogleMap
  }
};
</script>
