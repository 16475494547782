<template>
	<div>
		<h2>{{ editorLabel }}</h2>
		<v-btn-toggle>
			<v-btn flat v-on:click="showFontType=!showFontType">
    		<v-icon>format_size</v-icon>
				<span v-if="showText"> Format Text</span>
			</v-btn>
			<v-btn v-if="showOptions" flat :dark="value.background=='black'" :color="value.background+' '+value.backgroundAttribute" v-on:click="showBackground=!showBackground">
				<v-icon>format_color_fill</v-icon>
				<span v-if="showText"> Colour</span>
			</v-btn>
			<v-btn v-if="showOptions" flat v-on:click="chooseButton=!chooseButton;elementType='Button';">
    		<v-icon>format_shapes</v-icon>
				<span v-if="showText"> Button</span>
			</v-btn>
			<v-btn v-if="showOptions" flat v-on:click="chooseButton=!chooseButton;elementType='Image';">
    		<v-icon>satellite</v-icon>
				<span v-if="showText"> Image</span>
			</v-btn>
			<v-btn v-if="showOptions" flat v-on:click="chooseButton=!chooseButton;elementType='Event';">
    		<v-icon>event</v-icon>
				<span v-if="showText"> Event</span>
			</v-btn>
		</v-btn-toggle>
		<fontType v-show="showFontType" v-model="value.format" />
		<v-layout v-if="showBackground">
			<v-flex>
				<v-select
					v-model="value.background"
					:items="colours"
					label="Background colour"
					></v-select>
			</v-flex>
			<v-flex>
				<v-select
					v-model="value.backgroundAttribute"
					:items="colourAttributes"
					label="Attribute"
				></v-select>
			</v-flex>
		</v-layout>
		<v-textarea clearable solo
			id="inputText"
			ref="ta"
			label="Text"
			placeholder="Enter text"
			v-model="value.text"
			:elementLabel="elementLabel"
			/>
			<v-dialog v-model="chooseButton" persistent max-width="500">
				<v-card>
					<v-card-title class="headline">Add Element Detail</v-card-title>
					<v-card-text>
						<v-select
							:items="elementTypes"
							label="Element Type"
							v-model="elementType">
						</v-select>
						<div v-if="elementType==='Image'">
							<v-select
								:items="images"
								label="Image"
								v-model="imageName">
							</v-select>
							<v-text-field
								label="Height"
								placeholder="Optional Image Height"
								v-model="imageHeight"
							></v-text-field>
							<v-text-field
								label="Width"
								placeholder="Optional Image Width"
								v-model="imageWidth"
							></v-text-field>
						</div>
						<div v-if="elementType==='Button'">
							<v-select
								:items="buttonTypes"
								label="Button Type"
								v-model="buttonType">
							</v-select>
							<v-text-field
								v-if="buttonType !== 'Facebook' && buttonType !== 'LinkedIn'"
								label="Button Label"
								placeholder="Enter Label for Button"
								v-model="elementLabel"
							></v-text-field>
							<v-select
								:items="colours"
								label="Button Colour"
								v-model="buttonColour">
							</v-select>
							<v-select
								:items="colours"
								label="Text Colour"
								v-model="buttonTextColour">
							</v-select>
							<v-select v-if="buttonType === 'URL'"
								:items="targetTypes"
								label="Link Target"
								v-model="targetType">
							</v-select>
							<v-text-field
								label="Link"
								placeholder="Enter URL address or email address"
								v-model="elementLink"
							></v-text-field>
						</div>
						<div v-if="elementType==='Event'">
							<v-text-field
								label="Event Title"
								placeholder="Enter Title"
								v-model="elementLabel"
							></v-text-field>
							<v-text-field
								label="Event Date"
								placeholder="Enter Date"
								v-model="eventDate"
							></v-text-field>
							<v-text-field
								label="Event Time"
								placeholder="Enter Time"
								v-model="eventTime"
							></v-text-field>
							<v-text-field
								label="Event Location"
								placeholder="Enter Location"
								v-model="eventLocation"
							></v-text-field>
							<v-text-field
								label="Link"
								placeholder="Enter URL address for Location (eg. Google Maps)"
								v-model="elementLink"
							></v-text-field>
						</div>
					</v-card-text>
					<v-card-actions>
						<v-btn color="green darken-1" flat
							@click="chooseButton=false">
							Cancel
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" flat
							@click="updateLabel(0)">
							Add to Start
						</v-btn>
						<v-btn color="green darken-1" flat
							@click="updateLabel(1)">
							Add to End
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
	</div>
</template>
<script>
//	import json from './PagesJson.json'
//	import firebase from 'firebase'
//	import db from './firebaseInit'
import defaults from "../components/defaults.js";
import FontType from "../components/FontType.vue";

export default {
		name: 'editor',
		components: {
	    FontType
	  },
		props: {
	    value: {
				type: Object,
				required: true,
			},
			editorLabel: {
				type: String,
				required: false,
				default: "DEFAULT"
			},
			showText: {
				type: Boolean,
				required: false,
				default: true
			},
			showOptions: {
				type: Boolean,
				required: false,
				default: true
			},
			images: {
				type: Array,
				required: true
			}
	  },
		data() {
			return {
//				isLoggedIn: false,
//				currentUser: false
				colours: defaults.colours,
				colourAttributes: defaults.colourAttributes,
				chooseButton: false,
				elementLabel: "",
				colours: defaults.colours,
				buttonColour: "blue",
				buttonTextColour: "white",
				imageName: "",
				imageHeight: "",
				imageWidth: "",
				eventDate: "",
				eventTime: "",
				eventLocation: "",
				elementTypes: ["Button", "Image", "Event"],
				elementType: "Button",
				buttonTypes: ["URL", "Email", "LinkedIn", "Facebook"],
				buttonType: "URL",
				elementLink: "",
				targetTypes: ["_blank","_parent","_self","_top"],
				targetType: "_blank",
				showFontType: false,
				showBackground: false
			}
		},
	  created() {
			console.log(this.editorLabel+" value = ",this.value);
		},
		methods: {
			updateLabel(end) {
	      // Need to call modal to select options or add HTML for button in text area
				var inputText=document.getElementById('inputText');
				console.log("InputText: ",inputText);
	      var insertPosition = this.value.text.length;
				if (end === 0) {
					insertPosition = 0;
				}
				let textarea = this.$refs.ta
//				var cursorPosition = textarea.selectionStart;
	      var str = this.value.text;
				var href = '';
				if (this.elementType === "Button") {
					href = '<btn textColor="'+this.buttonTextColour+'" color="'+this.buttonColour+'" ';

					if (this.buttonType==="URL" || this.buttonType==="Facebook" || this.buttonType==="LinkedIn") {
						href += ' url="'+this.elementLink+'" target="'+this.targetType+'"';
					}

					if (this.buttonType) {
						href += ' type="'+this.buttonType+'"';
					}

						href += '>'+this.elementLabel+'</btn>';
				}

				if (this.elementType === "Event") {
					href = '<event date="'+this.eventDate+'" time="'+this.eventTime
					+'"location="'+this.eventLocation+'" url="'+this.elementLink+'">'
					+this.elementLabel+'</event>';
				}
				if (this.elementType === "Image") {
					href = '<pix url="'+this.imageName+'" height="'+this.imageHeight
					+'" width="'+this.imageWidth+'"/>';
				}


	      this.value.text=str.substr(0, insertPosition) + href + str.substr(insertPosition);
				this.chooseButton=false;
				let inputDom = document.getElementById("inputText");
					let ev = document.createEvent('HTMLEvents');
					ev.initEvent('change', true, true);
					inputDom.dispatchEvent(e);
	    }
		}
}
</script>
<style scoped>
.styled {
    border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-color: rgba(220, 0, 0, 1);
    background-image: linear-gradient(to top left,
                                      rgba(0, 0, 0, .2),
                                      rgba(0, 0, 0, .2) 30%,
                                      rgba(0, 0, 0, 0));
    box-shadow: inset 2px 2px 3px rgba(255, 255, 255, .6),
                inset -2px -2px 3px rgba(0, 0, 0, .6);
}

.styled:hover {
    background-color: rgba(255, 0, 0, 1);
}

.styled:active {
    box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
                inset 2px 2px 3px rgba(0, 0, 0, .6);
}
.facebook {
							background-color: red;
							 background: url(/src/assets/facebook.png) no-repeat;
							 cursor:pointer;
							 border: none;
					}
</style>
