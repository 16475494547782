<template>
  <div>
    <div>
      <h2>Search and add a pin</h2>
      <label>
        <gmap-autocomplete
          @place_changed="setPlace">
        </gmap-autocomplete>
        <button @click="addMarker">Add</button>
      </label>
      <br/>

    </div>
    <br>
    <gmap-map ref="mapRef"
      :center="center"
      :zoom="12"
      style="width:100%;  height: 400px;"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'
export default {
  name: "GoogleMap",
  computed: {
    google: gmapApi
  },
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: 45.508, lng: -73.587 },
      markers: [],
      places: [],
      currentPlace: null
    };
  },

  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      var heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.loadData(map),
      dissipating: false,
      map: map
      });

      this.geolocate();
    })
//    this.markers = [{position: this.center }];
  },

  methods: {
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },
    loadData(map) {
      let dataSet = [];
      dataSet.push({"coordinates":[43.732562, -79.411377],"weight":3});
      dataSet.push({"coordinates":[43.732562, -79.421377],"weight":2});
      var heatmapData = [];
      for (var i = 0; i<dataSet.length; i++) {
        console.log("dataSet "+i+": ",dataSet[i]);
        var coords = dataSet[i].coordinates;
        var latLng = new google.maps.LatLng(coords[1], coords[0]);
        var magnitude = dataSet.weight;
        var weightedLoc = {
          location: latLng,
          weight: Math.pow(2, magnitude)
        };
        heatmapData.push(weightedLoc);
      }
      return heatMapData;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
  }
}
</script>
