<template>
  <v-card :class="item.background+' '+item.backgroundAttribute">
    <v-card-title class="headline justify-center">
      <TextBox v-model="item.title" :corpId="corpId"/>
    </v-card-title>
    <v-container>
      <v-layout
        :column="item.position=='Top'||item.position=='Bottom'"
        :row="item.position=='Left'||item.position=='Right'"
        justify-center
        fill-height
        >
        <v-flex
        :class="item.position=='Bottom'||item.position=='Right'? 'order-md0 xs6':'order-md2 xs10'"
        >
          <TextBox v-model="item.text" :corpId="corpId" />
        </v-flex>
        <v-flex v-if="item.image" order-md1 xs6>
          <FireImage
            :item="item"
            :corpId="corpId"
            />
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import FireImage from "../components/FireImage.vue";
import defaults from "../components/defaults.js";
import TextBox from '../components/TextBox.vue'

export default {
  name: 'Card',
  props: {
    item: { type: Object, required: true },
    corpId: { type: String, required: true }
  },
  components: {
    FireImage,
    TextBox
  },
  computed: {
    textFormat: function() {
	    return defaults.addStyling(this.item,"text");
    },
    footerFormat: function() {
	    return defaults.addStyling(this.item,"footer");
    }
  },
  methods: {
    formatText(text) {
      if (typeof text === 'string') {
        return text.replace(new RegExp('\r?\n','g'), '<br /><br />');
      } else {
        return "";
      }
    }
  }
}
</script>
