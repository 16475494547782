<template>
	<v-toolbar>
		<v-toolbar-side-icon></v-toolbar-side-icon>
    <v-toolbar-title v-if="$parent.user">{{ $parent.user }}</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-toolbar-items class="hidden-sm-and-down">
				<v-btn flat
					v-for="(page,index) in json" v-bind:key="page.pageId"
					v-on:click="selectPage(page.pageId)"
					v-if="(page.loggedIn && $parent.user) || (!page.loggedIn && !$parent.user) || (typeof(page.loggedIn)==='undefined')">
					</v-btn>
					{{ page.title }}
		</v-toolbar-items>
	</v-toolbar>
</template>
<script>
//	import json from './PagesJson.json'
//	import firebase from 'firebase'
//	import db from './firebaseInit'

	export default {
		name: 'navbar',
		props: {
	    json: Array,
			corpId: String
	  },
		data() {
			return {
//				isLoggedIn: false,
//				currentUser: false
			}
		},
		methods: {
		    selectPage: function (pageId) {
					console.log("Selected Page ID is "+pageId);
					this.$parent.selectedPage = pageId;
		    }
		  }
}
</script>

<style scoped>
.email { padding-right: 10px; }
</style>
