<template>
  <div data-app>
    <v-dialog v-model="chooseCorp" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Select Corp</v-card-title>
        <v-card-text>
          <v-select
            :items="corps"
            label="Corp"
            v-model="selectedCorp"
            attach>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat
            @click="chooseCorp = false; $parent.corpId=selectedCorp; fetchData(selectedCorp);">
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid grid-list-md text-xs-center v-if="chooseCorp==false">
      <v-layout>
        <v-flex xs12>
          <div>
            <v-tabs fixed-tabs light color="gray" slider-color="blue" @change="changePage" v-model="tabIndex">
              <v-tab
                v-for="(page, index) in navigation"
                :key="page.pageId"
                ripple>
                    <v-icon v-if="selectedPage===page.pageId && tabIndex!==0" v-on:click="movePage('left')" color="grey">arrow_left</v-icon>
                  {{ page.title }}
                    <v-icon v-if="selectedPage===page.pageId && tabIndex!==navigation.length-1" v-on:click="movePage('right')" color="grey">arrow_right</v-icon>
              </v-tab>
              <v-btn color="green darken-1" round v-on:click="addPage()">
                <v-icon>add</v-icon>New Page
              </v-btn>
            </v-tabs>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Add Images</v-card-title>
        <v-card-text>
          <Uploader @addedImage="didAddImage" v-bind:images="images" :corpId="corpId"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
  <v-layout>
    <v-flex xs12>
      <v-text-field label="Page Title"
      placeholder="Page Title"
      v-model="pageJson.title" />
      <br/>
      <v-select
        :items="layoutItems"
        label="Select Page Layout"
        v-model="pageLayout">
      </v-select>
    </v-flex>
  </v-layout>
  <v-layout row>
    <v-flex xs12 style="border: 1px solid blue; margin: 10px;">
      <editor v-model="pageJson.header" editorLabel="Header" :images="images"/>
    </v-flex>
  </v-layout>
  <v-layout>
    <v-flex xs12 style="border: 1px solid red;">
        <Page v-model="pageJson" :corpId="corpId"/>
    </v-flex>
  </v-layout>
  <v-layout row wrap>
    <v-flex v-for="(column,i) in pageJson.columns"
      :xs12="pageLayout=='Simple'"
      :xs6="pageLayout=='2 Columns'"
      :xs4="pageLayout=='3 Columns'"
      :xs3="pageLayout=='4 Columns'"
      :xs2="pageLayout=='6 Columns'"
      :key="i">
      <v-icon v-if="i!==0" v-on:click="moveColumn(i,'left')" color="grey">arrow_left</v-icon>
      <v-btn
        v-on:click="addItem(i)"
        color="green"
        small
        round>
        <v-icon>add</v-icon> Section
      </v-btn>
      <v-icon v-if="i<pageJson.columns.length-1" v-on:click="moveColumn(i,'right')" color="grey">arrow_right</v-icon>
        <v-card
          v-for="(item,index) in pageJson.columns[i].items"
          style="border: 1px solid blue; padding: 10px; margin: 5px;"
          :key="index"
        >
          <v-layout row>
            <v-flex xs8>
<!--              <v-text-field label="Title" placeholder="Title" v-model="item.title.text" />
		          <fontType v-model="item.title.format" /> -->
            <editor v-model="item.title" :showOptions="false" :showText="false" editorLabel="Title" :images="images"/>
            </v-flex>
            <v-flex xs4>
              <div class="v-btn-toggle">
                <v-btn flat v-on:click="deleteItem(i,index)">
                  <v-icon color="pink">clear</v-icon>
                </v-btn>
                <v-btn flat v-on:click="move(i, index, 'down')">
                  <v-icon color="grey">arrow_downward</v-icon>
                </v-btn>
                <v-btn flat v-on:click="move(i, index, 'up')">
                  <v-icon color="grey">arrow_upward</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex>
              <v-select
                :items="colours"
                label="Background"
                v-model="item.background"
                attach>
              </v-select>
            </v-flex>
            <v-flex>
              <v-select
                :items="colourAttributes"
                label="Attribute"
                v-model="item.backgroundAttribute"
                attach>
              </v-select>
            </v-flex>
          </v-layout>
          <v-layout row style="border: 1px solid grey; padding: 10px;">
            <v-flex md4>
              <FireImage
                :item="item"
                :corpId="corpId"
                v-if="item.image"
                />
              <v-select
                :items="images"
                label="Image"
                v-model="item.image"
                attach>
              </v-select>
              Upload Image
              <v-btn
                small fab v-on:click="openImageDialog(i,index)">
                <v-icon>add_photo_alternate</v-icon>
              </v-btn>
            </v-flex>
            <v-flex v-if="item.image">
              <v-select
                :items="positions"
                label="Image Position"
                v-model="item.position"
                attach>
              </v-select>
              <v-text-field
                label="Height"
                placeholder="Blank or in Pixels"
                v-model="item.imageHeight"
              ></v-text-field>
              <v-text-field
                label="Max Height"
                placeholder="Blank or in Pixels"
                v-model="item.imageMaxHeight"
              ></v-text-field>
              <v-text-field
                label="Aspect Ratio"
                placeholder="Blank or like 1.778"
                v-model="item.imageAspect"
              ></v-text-field>
              <editor v-model="item.caption" :showText="false" editorLabel="Image Caption" :images="images"/>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex md12>
              <editor v-model="item.text" :showText="false" editorLabel="Text" :images="images"/>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12 style="border: 1px solid blue; margin: 10px;">
        <editor v-model="pageJson.footer" editorLabel="Footer" :images="images"/>
      </v-flex>
    </v-layout>
    <v-layout v-if="contacts">
      <IconRow :facebook="contacts.facebook" :linkedIn="contacts.linkedIn" :email="contacts.email" :colour="contacts.colour" />
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <h1>Optional Icons</h1>
        <v-select
          :items="colours"
          label="Colour"
          v-model="contacts.colour"
          attach>
        </v-select>
        <v-text-field
          v-model="contacts.email"
          label="Email Contact (optional)"
        />
        <v-text-field
          v-model="contacts.facebook"
          label="Facebook Link (optional)"
        />
        <v-text-field
          v-model="contacts.linkedIn"
          label="LinkedIn Link (optional)"
        />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <v-btn v-on:click="cancel">Cancel</v-btn>
        <v-btn v-on:click="save">Save</v-btn>
        </v-flex>
    </v-layout>
  </v-container>
  </div>
</template>
<script>
//import * as firebase from 'firebase/app'

import db from '../components/firebaseInit'
import Uploader from "../components/Uploader.vue";
import Card from "../components/Card.vue";
import Editor from "../components/Editor.vue";
import FontType from "../components/FontType.vue";
import defaults from "../components/defaults.js";
import IconRow from "../components/IconRow.vue";
import TextBox from "../components/TextBox.vue";
import Page from '../components/Page.vue'
import FireImage from '../components/FireImage.vue'

export default {
  name: 'setup',
  components: {
    Uploader,
    Card,
    Editor,
    Page,
    IconRow,
    FontType,
    TextBox,
    FireImage
  },
  methods: {
    cancel() {
//      alert("Cancel pressed");
      this.fetchJson(this.selectedPage);
//      this.fetchData(this.corpId);
    },
    updateText(attr, format,i,index) {
      this.pageJson.columns[i].items[index][attr] = format;
      console.log("updateText ",attr,format);
    },
    save() {

      function createJsonArray(string) {
        console.log("recieved String = "+string);
        let newArray = [];
        let jsonObject = JSON.parse("[ "+string+" ]");
        for (var key in jsonObject) {
          if (jsonObject.hasOwnProperty(key)) {
            newArray[key]=jsonObject[key];
          }
        }
        return newArray;
      }

//      let paragraphsArray = createJsonArray(this.paragraphs);
//      console.log("paragraphsArray = "+JSON.stringify(paragraphsArray));
//      let cardsArray = createJsonArray(this.cards);
      let titleChanged = false;
      let self=this;
      this.navigation.forEach(function(page){
        if (page.pageId == self.selectedPage) {
          if (page.title !== self.pageTitle) {
            page.title = self.pageJson.title;
//            page.header = self.pageJson.header;
//            page.footer = self.pageJson.footer;
            titleChanged = true;
          }
        }
      });
//      if (titleChanged) {
        let corpTransx = db.collection("corps").doc(this.corpId);
        let resp = corpTransx.update({
          navigation: this.navigation,
          contacts: this.contacts
        })
        .then()
        .catch(function(error) {
          console.error("Error updating document: ", error);
        });
        console.log("resp = ",resp);
//      }

      let refTransx = db.collection("pages").doc(this.selectedPage);
      let response = refTransx.update({
        title: this.pageJson.title,
        header: this.pageJson.header,
        footer: this.pageJson.footer,
        layout: this.pageLayout,
        columns: this.pageJson.columns
      })
      .then()
      .catch(function(error) {
      // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
      this.currentJson = JSON.parse(JSON.stringify(this.pageJson))
      console.log("response = ",response);
    },
    fetchData(corpId) {
      var ref = db.collection("corps").doc(corpId)
      let self=this;
      ref.get()
      .then(doc => {
        if (doc.exists && doc.data()) {
            console.log("Data = ",doc.data());
            self.navigation=doc.data().navigation;
            self.images=doc.data().images;
            self.images.unshift("");
            self.contacts=doc.data().contacts;
            self.fetchJson(doc.data().navigation[0].pageId);
        } else {
            // doc.data() will be undefined in this case
            console.log("No such corp!  Creating new Corp.");
            ref.set({
              navigation: [],
              images: []
            })
            .then(function() {
                console.log("Document successfully written!");
                self.addPage();
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });
        }
      })
      .catch( error => {
        console.log("ref error",error)
      })
    },
    cleanJson(json) {
      var i=0;
      var content = "";
      for (var key in json) {
        if (json.hasOwnProperty(key)) {
          if (i>0) { content += ",\n"; }
          content+=JSON.stringify(json[key]);
          i++;
        }
      }
      return content;
    },
    hasImage(i) {
        i='ddd';
    },
    openImageDialog(i, index) {
      this.dialog = true;
      this.selectedBox = [i,index];
    },
    didAddImage(image) {
//      console.log("did add image called: "+image);
      this.pageJson.columns[this.selectedBox[0]].items[this.selectedBox[1]].image = image;
      this.dialog = false;
    },
    didAddPage(id, title) {
      let corpTransx = db.collection("corps").doc(this.corpId);
      this.navigation.push({ "pageId": id, "title": title });
      this.tabIndex = this.navigation.length - 1;
      this.fetchJson(id);
      let resp = corpTransx.update({
        navigation: this.navigation
      })
      .then()
      .catch(function(error) {
        console.error("Error updating document: ", error);
      });
      console.log("resp = ",resp);
    },
    addPage() {
      let refTransx = db.collection("pages");
      let self=this;
      let response = refTransx
      .add({
        title: "New",
        layout: "Simple",
        columns: [{ "items":[] }],
        header: JSON.parse(JSON.stringify(this.newTextItem)),
        footer: JSON.parse(JSON.stringify(this.newTextItem))
      })
      .then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
        self.didAddPage(docRef.id, "New");
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
    },
    addItem(i) {
      var newJson = {
        "title": JSON.parse(JSON.stringify(this.newTextItem)),
        "text": JSON.parse(JSON.stringify(this.newTextItem)),
        "caption": JSON.parse(JSON.stringify(this.newTextItem))
      };
      var newArray = [newJson];
      if (this.pageJson.columns[i]) {
        newArray = this.pageJson.columns[i].items;
        newArray.push(newJson);
      }
      var column = {'items': newArray };
      this.$set(this.pageJson.columns, i, column);
      console.log("column: ",this.pageJson.columns[i]);
    },
    move(col, i, direction) {
      console.log("Col: "+col+", "+i+", "+direction);
      var current = JSON.parse(JSON.stringify(this.pageJson.columns[col].items[i]));
      if (i>0 && direction=="up") {
        this.pageJson.columns[col].items.splice(i,1);
        this.pageJson.columns[col].items.splice(i-1,0,current);
      } else {
        if (i+1<=this.pageJson.columns[col].items.length && direction=="down") {
          this.pageJson.columns[col].items.splice(i,1);
          this.pageJson.columns[col].items.splice(i+1,0,current);
        }
      }
    },
    moveColumn(column, direction) {
      var move=-1;
      if (direction==="right") { move=1; }
      var columnToMove = this.pageJson.columns[column];
      var swapColumn = this.pageJson.columns[column+move];
      this.pageJson.columns.splice(column,1,swapColumn);
      this.pageJson.columns.splice(column+move,1,columnToMove);
    },
    movePage(direction) {
//      alert("Page: "+id+" to "+direction);
      this.tabBeingMoved = true;
//      console.log("MovePage: "+id+" to "+direction);
//      console.log("tabIndex: ",this.tabIndex);
//      console.log("navigation: ",this.navigation);
//      var navigationCopy = JSON.parse(JSON.stringify(this.navigation));
      var pageToMove = this.navigation[this.tabIndex];
      this.selectedPage = pageToMove.pageId;
//      console.log("newNavigation 1: ",navigationCopy);
      var move=-1;
      if (direction==="right") { move=1; }
      var swapPage = this.navigation[this.tabIndex+move];
      this.navigation.splice(this.tabIndex,1,swapPage);
      this.navigation.splice(this.tabIndex+move,1,pageToMove);

      let self = this;

      let corpTransx = db.collection("corps").doc(this.corpId);
      let resp = corpTransx.update({
        navigation: self.navigation
      })
      .then(function() {
        self.tabIndex = parseInt(self.tabIndex)+move;
        }
      )
      .catch(function(error) {
        console.error("Error updating document: ", error);
      });
      console.log("resp = ",resp);
    },
    deleteItem(col, index) {
      console.log("Delete col="+col+", i="+index);
      this.pageJson.columns[col].items.splice(index,1);
    },
    changePage() {
      console.log("Selected Tab",this.tabIndex);
      console.log("Selected Page",this.navigation[this.tabIndex].pageId);
      if (this.tabBeingMoved === false) {
        if (JSON.stringify(this.pageJson) !== JSON.stringify(this.currentJson)) {
          if(confirm("Are you sure you want to leave page without saving?")){
            this.fetchJson(this.navigation[this.tabIndex].pageId)

          } else {

            var lastTab = 0;
            for (var i=0; i<this.navigation.length; i++) {
              if (this.navigation[i].pageId === this.selectedPage) {
                lastTab = i;
                break;
              }
            }
            console.log("Last Tab ",lastTab);
            this.$nextTick(() => {
              this.tabIndex = lastTab;
            })
          }
        } else {
          this.fetchJson(this.navigation[this.tabIndex].pageId)
        }
      } else {
        this.tabBeingMoved = false;
      }
    },
    fetchJson(page) {
      let self = this;
      var ref = db.collection("pages").doc(page)
      ref.get()
      .then(doc => {
        if (doc.exists && doc.data()) {
          var jsonData = doc.data();
          console.log("JSON Data = ",jsonData);
          if (!jsonData.layout) {
            self.pageLayout = "Simple"
            self.pageJson.layout = "Simple"
          } else {
            self.pageLayout = jsonData.layout
            self.pageJson.layout = jsonData.layout
          }
//          this.pageJson.layout = this.pageLayout
          if (!jsonData.title) {
            self.pageJson.title = ""
          } else {
            self.pageJson.title = jsonData.title
          }
          if (!jsonData.header) {
            console.log("No header");
            self.pageJson.header = JSON.parse(JSON.stringify(this.newTextItem))
          } else {
            self.pageJson.header = jsonData.header
          }
          if (!jsonData.columns) {
            console.log("No columns");
            self.pageJson.columns = [{"title":JSON.parse(JSON.stringify(this.newTextItem)),"columns":[{"items":[{"title":JSON.parse(JSON.stringify(this.newTextItem))}]}],"header":{"text":"", "format":{}},"footer":{"text":"", "format":{}}}]
          } else {
            self.pageJson.columns = jsonData.columns
          }
          if (!jsonData.footer) {
            console.log("No footer");
            self.pageJson.footer = JSON.parse(JSON.stringify(self.newTextItem))
          } else {
            self.pageJson.footer = jsonData.footer
          }
          self.currentJson = JSON.parse(JSON.stringify(self.pageJson))
          self.selectedPage=page;
        } else {
            // doc.data() will be undefined in this case
            console.log("No such page!");
        }
      })
      .catch( error => {
        console.log("ref error",error)
      })
    }
  },
  data() {
    const textItem = { "format":{ "type":[], "textSize":"title", "colour":"black", "align":"center"}, "text":""};
    return {
      navigation: [],
      newTextItem: textItem,
      pageJson:{
            "title":"Loading",
            "columns":[
              { 0: { "items":[ { 0: {} } ] } }
              ],
              "header":{"text":"","format":{}},
              "footer":{"text":"","format":{}}
      },
      currentJson: {},
      selectedPage: null,
      selectedButton: null,
      cards:"",
      pageLayout: "Simple",
      layoutItems: ["Simple", "2 Columns", "3 Columns", "4 Columns", "6 Columns"],
      images: [],
      colours: defaults.colours,
      colourAttributes: defaults.colourAttributes,
      selectedBox: [],
      positions: ["Top", "Left", "Right", "Bottom"],
      alignmentItems: ["justify-start", "justify-center", "justify-end"],
      dialog: false,
  		imageName: '',
  		imageUrl: '',
  		imageFile: '',
      chooseCorp: false,
      corps: [],
      selectedCorp: "",
      tabIndex:0,
      contacts: {},
      tabBeingMoved: false
    }
  },
  props: {
    corpId: {
      type: String,
      required: true
    }
  },
  watch: {
    pageLayout: function(newLayout, oldLayout) {
      var newArray = [];
      var n = 0;
      switch (newLayout) {
        case "2 Columns":
          n=1;
          break;
        case "3 Columns":
          n=2;
          break;
        case "4 Columns":
          n=3;
          break;
          case "6 Columns":
            n=5;
            break;
        default:
          n=0;
      }
      for (var i=0; i<=n; i++) {
        if (!this.pageJson.columns[i]) {
//          this.$set(this.pageJson.columns, i, { "items": [] });
          this.pageJson.columns[i] = { "items":[] };
        }
      }
//      this.$set(this.pageJson, 'layout', newLayout);
      this.pageJson.layout = newLayout;
      this.currentJson = JSON.parse(JSON.stringify(this.pageJson))
    },
    corpId: function(newCorp, oldCorp) {
      console.log("newCorp = "+newCorp);
      if (newCorp=="") {
        this.chooseCorp = true;
      }
    }
  },
  created() {
		console.log("pageJson = ",this.pageJson)
    let user = this.$parent.user;
		console.log("Current User = "+user)
		if (!user) {
			alert("User Data not found")
		} else {
			var userRef = db.collection("users").doc(user)
			userRef.get()
			.then(doc => {
				if (doc.exists && doc.data().corps[0]) {
              this.corps = doc.data().corps;
              if (this.corps.length>1) {
                this.chooseCorp = true;
              } else {
                var corp = doc.data().corps[0]
  							var docRef = db.collection("corps").doc(corp)
  							docRef.onSnapshot(doc => {
  								if (doc.exists) {
  												console.log("Document data:", doc.data());
  												this.corpId = corp;
//                          this.$parent.corpId = corpId;
                          this.fetchData(corp);
  										} else {
  												// doc.data() will be undefined in this case
  												console.log("No such document!");
  										}
  								})
              }
						} else {
								// doc.data() will be undefined in this case
								console.log("No such user!");
						}
				})
				.catch( error => {
						console.log("userRef error",error)
					}
				)
			}
	}
//    this.fetchData();
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
