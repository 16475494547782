<template>
  <div :class="textFormat" v-bind:style="{ opacity: transparency }">
    <span v-html="displayText"></span>
  </div>
</template>

<script>
//import firebase from 'firebase';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import defaults from "../components/defaults.js";

export default {
  name: 'TextBox',
  props: {
    value: {
      type: Object,
      required: true
    },
    transparency: {
      type: String,
      default: '1.0'
    },
    corpId: {
      type: String, required: true
    }
  },
  computed: {
    textFormat: function() {
      return defaults.addStyling(this.value.format,"text");
    }
  },
  data() {
    return {
      images: [],
      displayText:""
    }
  },
  created: function () {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, waiting until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
//    this.debouncedGetAnswer = _.debounce(this.formatText, 500)
  },
  watch: {
      value: {
        deep: true,
        immediate: true,
        handler(value) {
          this.formatText();
          console.log("Value changed");
        }
      }
  },
  methods: {
    formatText() {

        if (!this.value.text && this.value.title) {
          this.value.text = this.value.title;
        }

        var textCopy = this.value.text;

        var returnText = this.addEventHtml(this.addButtonHtml(textCopy.replace(new RegExp('\r?\n','g'), '<br /><br />')));

      this.displayText = returnText;
      this.addImageHtml(returnText);
    },
    findValue(attr, string) {
      var regEx = "("+attr+"=\")(.*?)(?=\")";
      var replaceString = attr+"=\"";
      if (attr === "label") {
        regEx = "(>)(.*?)(?=<)";
        replaceString = ">";
      }
//      console.log("regEx: ",regEx);
      var result = string.match(new RegExp(regEx,'gm'));
//      console.log("result: ",result);

      if (!result) {
        if (attr === "color") {
          result = ["blue"];
        } else {
          if (attr === "textColor") {
            result = ["white"];
          } else {
            if (attr === "target") {
              result = ["_self"];
            } else {
              if (attr === "type") {
                result = ["button"];
              } else {
                result=[" "];
              }
            }
          }
        }
      }
      return result[0].replace(replaceString,'');
    },
    addImageHtml(text) {
//      console.log("Add Image HTML called");
//      var imagesText = this.value.text;
//      var eventArray = text.match(new RegExp('(<event).*?(?<=<\/event>)','gm'));
      var imageArray = text.match(new RegExp('(<pix).*?(\/>)','gm'));
//      console.log("imageArray:",imageArray);
      let storage = firebase.storage();
      let storageRef = storage.ref();
      if (imageArray) {
        for (var i=0; i<imageArray.length; i++) {

          var str = imageArray[i];
          var url = this.findValue('url',str);
          var height = this.findValue('height',str);
          var width = this.findValue('width',str);
          let starsRef = storageRef.child(this.corpId+'/'+url);
//          console.log("starsRef",starsRef);
          let self=this;
//          this.images[i] = starsRef.getDownloadURL().toString();
          var downloadUrl = starsRef.getDownloadURL()
          .then(function(url) {
              var html = "<img class='v-img' height='"+height+"' width='"+width+"' src='"+url+"'></img>";
//              console.log("displayText", self.displayText);
              self.displayText = self.displayText.replace(str,html);
//              console.log("str", str);
//              console.log("html", html);
//              console.log("downloadUrl",url)
              return url;
          })
        }
      }
//      return text;
//      console.log("images",this.images);
    },
    addEventHtml(text) {
      var returnText = text;
//      var eventArray = text.match(new RegExp('(<event).*?(?<=<\/event>)','gm'));
      var eventArray = text.match(new RegExp('(<event).*?(<\/event>)','gm'));
//      console.log("eventArray:",eventArray);
      if (eventArray) {
        for (var i=0; i<eventArray.length; i++) {
          var str = eventArray[i];
//          console.log("str "+i+":",str);
          var date = this.findValue('date',str);
          var time = this.findValue('time',str);
          var location = this.findValue('location',str);
          var url = this.findValue('url',str);
          var label = this.findValue('label',str);

          var html = "<div class='event'><p><h3>"+label+"</h3></p>";
          html += "<p>Date: "+date+"</p>";
          html += "<p>Time: "+time+"</p>";
          html += "<p>Location: <a href='"+url+"'>"+location+"</a></p></div>";

//          console.log("html",html);
          returnText=returnText.replace(str,html);

        }
        return returnText;
      } else {
        return text;
      }
    },
    addButtonHtml(text) {
      // buttonTypes:
      // URL <button onclick='window.open(url) >
      // Email <button onclick='location.href="mailto:xxxx"' >
      // LinkedIn
      // Facebook <button onclick='window.open("https://www.facebook.com/profile.php?id=xxxx")' >
      var returnText = text;
//      var btnArray = text.match(new RegExp('(<btn).*?(?<=<\/btn>)','gm'));
      var btnArray = text.match(new RegExp('(<btn).*?(<\/btn>)','gm'));
//      console.log("btnArray:",btnArray);

      if (btnArray) {
        for (var i=0; i<btnArray.length; i++) {
          var str = btnArray[i];
//          console.log("str "+i+":",str);
          var type = this.findValue('type',str);
          var url = this.findValue('url',str);
          var label = this.findValue('label',str);
          var color = this.findValue('color',str);
          var textColor = this.findValue('textColor',str);
          var target = this.findValue('target',str);

          var classText="v-btn small ";
          if (type == "Facebook") {
            classText="icon fa fa-facebook-square ";
            label= " ";
          }
          if (type == "Email") {
            classText="icon fa fa-envelope ";
            label= " ";
          }

          if (type == "LinkedIn") {
            classText="icon fa fa-linkedin-square ";
            label= " ";
          }

          var html =" <button class='"+classText+color+" "+textColor
          +"--text' onclick='location.href=\""+url+"\"' target='"+target+"'>"+label+"</button> ";

//          console.log("html",html);
          returnText=returnText.replace(str,html);
        }
        return returnText;
      } else {
        return text;

      }
//      console.log("url",url);
//      console.log("label",label);
//      html = text.replace(new RegExp('<fb>(.*)<\/fb>','g'),url);
    }
  }
}
</script>
