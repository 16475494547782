import Vue from 'vue';
import Router from 'vue-router';
//import Hello from '@/components/Hello';
import Home from "../views/Home.vue";
import Root from "../views/Root.vue";
//import Setup from "./views/Setup.vue";
import Modify from "../views/Modify.vue";
import Gmap from "../views/Map.vue";


Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "root",
      component: Root
    },
    {
      path: "/portal",
      name: "modify",
      component: Modify
    },
    {
      path: "/home/:corpId",
      name: "home",
      component: Home
    },
    {
      path: "/map/",
      name: "Gmap",
      component: Gmap
    }
  ]
//  mode: 'history'
});
