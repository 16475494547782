<template>
  <div class="display-1" v-html="title"></div>
</template>

<script>
import defaults from "../components/defaults.js";

export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
  },
  methods: {
    formatText(text) {
      return text.replace(new RegExp('\r?\n','g'), '<br /><br />');
    }
  }
}
</script>
