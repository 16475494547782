<template>
  <v-container fluid grid-list-md text-xs-center v-if="value.columns">
    <Title :title="value.title" /><br/>
    <TextBox v-if="value.header" v-model="value.header" :corpId="corpId"/>
      <v-layout v-if="value.columns" row fill-height v-for="(r,index) in maxItems" :key="index">
        <v-flex d-flex v-for="(column,i) in value.columns"
          :xs12="value.layout=='Simple'"
          :xs6="value.layout=='2 Columns'"
          :xs4="value.layout=='3 Columns'"
          :xs3="value.layout=='4 Columns'"
          :xs2="value.layout=='6 Columns'"
          :key="i">
          <Card v-if="value.columns[i].items[r-1]" :item="value.columns[i].items[r-1]" :corpId="corpId" />
        </v-flex>
      </v-layout>
    <TextBox v-if="value.footer" v-model="value.footer" :corpId="corpId"/>
  </v-container>
</template>

<script>
//import firebase from 'firebase'
import db from '../components/firebaseInit'
import Title from '../components/Title.vue'
import TextBox from '../components/TextBox.vue'
import Card from '../components/Card.vue'

export default {
  name: 'page',
  components: {
    Title,
    TextBox,
    Card
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    corpId: {
      type: String,
      required: true
    }
  },
  computed: {
    maxItems: function() {
      var max=0;
      if (this.value) {
        if (this.value.columns[0].items) {
          for (var i=0; i<this.value.columns.length;i++) {
            if (this.value.columns[i].items.length > max) {
              max = this.value.columns[i].items.length
            }
          }
        }
      }
      console.log("max",max);
      return max+1;
    }
  }
}
</script>
