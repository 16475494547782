<template>
  <v-layout row wrap>
    <v-flex xs12>
    <div class="center">
      <v-btn fab dark v-if="email" :color="colour" :href="'mailto:'+email">
        <v-icon dark>email</v-icon>
      </v-btn>
      <v-btn fab dark v-if="facebook" :color="colour" :href="facebook" target="_blank">
        <font-awesome-icon :icon="['fab', 'facebook-square']" size='2x' />
      </v-btn>
      <v-btn fab dark v-if="linkedIn" :color="colour" :href="linkedIn" target="_blank">
        <font-awesome-icon :icon="['fab', 'linkedin']" size='2x' />
      </v-btn>
  </div>
  </v-flex>
</v-layout>
</template>

<script>

export default {
  name: 'IconRow',
  props: {
    colour: {type: String, required: false },
    facebook: {type: String, required: false },
    linkedIn: {type: String, required: false },
    email: {type: String, required: false }
  },
  mounted () {
//    console.log("colour = "+this.colour);
//    console.log("linkedIn = "+this.linkedIn);

  }
}
</script>
