<template>
	<div data-app>
		<v-layout :class="item.position=='Top'||item.position=='Bottom'||item.type=='Image' ? 'justify-center':''">
			<v-flex xs12>
				<v-card outlined>
					<v-img
						:src="imageUrl"
						:aspect-ratio="item.imageAspect"
						:height="item.imageHeight"
						:max-height="item.imageMaxHeight"
						:class="item.background+' '+item.backgroundAttribute"
						contain
					>
						<v-container v-if="item.caption.text" fill-height fluid>
							<v-layout fill-height justify-space-around>
								<v-flex md8 align-self-start>
	      					<TextBox v-model="item.caption" transparency='0.8' :corpId="corpId"/>
								</v-flex>
							</v-layout>
						</v-container>
					</v-img>
				</v-card>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
	import * as firebase from 'firebase/app';
	import 'firebase/storage';
	import defaults from "../components/defaults.js";
	import TextBox from '../components/TextBox.vue'
	export default {
		name: 'FireImage',
		props: {
	    item: { type: Object, required: true },
	    corpId: {type: String, required: true }
	  },
		components: {
			TextBox
		},
		data() {
			return {
				imageUrl: ""
			}
		},
		created: function() {
			this.getImageSource(this.item.image, this.corpId);
//			this.url=defaults.getImageSource(firebase, this.corpId, this.imageName);
		},
		methods: {
			getImageSource() {
				if (this.imageName) {
					let storage = firebase.storage();
					let storageRef = storage.ref();
					let starsRef = storageRef.child(this.corpId+'/'+this.imageName);
//					console.log("Get Image: ",this.imageName);
					let self=this;
					starsRef.getDownloadURL()
					.then(function(url) {
						self.imageUrl = url; })
					.catch(function(error) {
									console.log("download error: ",error);
						switch (error.code) {
								case 'storage/object-not-found':
									// File doesn't exist
									console.log("image download error: File doesn't exist");
									break;

								case 'storage/unauthorized':
									// User doesn't have permission to access the object
									console.log("image download error: User doesn't have permission to access the object");
									break;

								case 'storage/canceled':
									// User canceled the upload
									console.log("image download error: User canceled the upload");
									break;

								case 'storage/unknown':
									// Unknown error occurred, inspect the server response
									console.log("image download error: Unknown error occurred, inspect the server response");
									break;
							}
					})
				} else {
					self.imageUrl = "";
				}
			}
		},
		watch: {
    		// whenever question changes, this function will run
		    imageName: function (newItem, oldItem) {
					this.getImageSource(newItem, this.corpId);
				}
		},
		computed: {
			imageName() {
				return this.item.image;
			},
			textFormat: function() {
	      return defaults.addStyling(this.item,"text");
	    },
			captionFormat: function() {
				return defaults.addStyling(this.item,"caption");
	    },
		}
}
</script>

<style scoped>
</style>
