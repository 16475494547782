//import firebase from 'firebase';
//import * as firebase from 'firebase/app';

export default Object.freeze(
  {
    colours: ["","black","blue","pink","purple","deep-purple","indigo",
    "cyan","light-blue","light-green","lime","teal","green","yellow",
    "red", "grey","amber","orange","deep orange","blue-grey","brown",
    "white"].sort(),
    colourAttributes: ["","lighten-1","lighten-2","lighten-3","lighten-4",
    "lighten-5","darken-1","darken-2","darken-3","darken-4"].sort(),
    addStyling: function(item, attr) {
        item = Object.is(item, undefined) ? {} : item;
        let colour = "black";
        let colourVar="colour";
        let colourAttribute="colourAttribute";
        let formatAttribute="type";
        let backgroundVar="background";
        let backgroundAttribute="backgroundAttribute";
        let textSize="body-1";
        if (item.textSize) { textSize = item.textSize };

        if (item[colourVar]) { colour = item[colourVar] };
        colour+="--text";
        if (item[colourVar] && item[colourAttribute]) {
          colour+=' text--'+item[colourAttribute];
        }

        let tFormat = "";
        let styleLength=0;

        if (item[formatAttribute]) {
          styleLength = item[formatAttribute].length;
        }
        for(var i=0; i< styleLength; i++) {
          tFormat+=item[formatAttribute][i]+" ";
        }
        let alignAttribute="align";
        let alignFormat = "";
        if (item[alignAttribute]) {
          alignFormat = item[alignAttribute];
        }

        let backgroundFormat="";
        if (item[backgroundVar]) {
          backgroundFormat=item[backgroundVar];
        }
        if (item[backgroundVar] && item[backgroundAttribute]) {
          backgroundFormat+=' '+item[backgroundAttribute];
        }

        tFormat+=" "+backgroundFormat+" "+colour+" "+alignFormat+" "+textSize;
//        console.log("tFormat = ",tFormat);
        return tFormat;
    },
/*    getImageSource: function(imageName, corpId) {
      console.log("Image: ",imageName);
      console.log("CorpId: ",corpId);

      if (imageName && corpId) {
        let storage = firebase.storage();
        let storageRef = storage.ref();
        let starsRef = storageRef.child(corpId+'/'+imageName);
				console.log("starsRef: ",starsRef);
        starsRef.getDownloadURL();
//        return imageUrl;
        .then(function(url) {
          return url;
          console.log("imageUrl: ",url);
        })
        .catch(function(error) {
                console.log("download error: ",error);
          switch (error.code) {
              case 'storage/object-not-found':
                // File doesn't exist
                console.log("image download error: File doesn't exist");
                break;

              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                console.log("image download error: User doesn't have permission to access the object");
                break;

              case 'storage/canceled':
                // User canceled the upload
                console.log("image download error: User canceled the upload");
                break;

              case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                console.log("image download error: Unknown error occurred, inspect the server response");
                break;
            }
        })
      } else {
        return "";
        console.log("No imageUrl: ");
      }
//      return imageUrl;
    }
  }*/
}
)
