<template>
  <div class="root">
    <p>Loading...</p>
  </div>
</template>

<script>

export default {
  name: "root",
  components: {

  },
  mounted () {
    this.$router.push('/home/judyChu');
  },
  data() {
    return {
    }
  }
}
</script>
