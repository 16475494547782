<template>
  <div class="home">
    <v-toolbar>
      <v-toolbar-items class="hidden-sm-and-down">
          <v-btn flat
            v-for="(page,i) in navigation"
            v-bind:key="page.pageId"
            v-bind:class="['tab-button', { active: currentPage === page.pageId }]"
            v-on:click="currentPage = page.pageId">
            <v-icon v-if="!page.image && i===0">home</v-icon>
            <TextBox v-model="navigation[i]" :corpId="corpId" />
          </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <HomePage v-bind:page="currentPage" :contacts="contacts" :corpId="$route.params.corpId"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomePage from "../components/HomePage.vue";
//import firebase from 'firebase'
import db from '../components/firebaseInit'
import TextBox from '../components/TextBox.vue'


export default {
  name: "home",
  components: {
    HomePage,
    TextBox
  },
  data() {
    return {
      navigation: [],
      currentPage: "none",
      contacts: {},
      corpId: this.$route.params.corpId,
      pageId: this.$route.params.pageId
    }
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      if (this.pageId) {
        this.currentPage = this.pageId;
      }
    }
  },
  created() {
//    this.corpId = this.$route.params.corpId

//    console.log("router page", this.$route.params.pageId);

    var ref = db.collection("corps").doc(this.corpId)
    ref.get()
    .then(doc => {
      if (doc.exists && doc.data()) {
//          console.log("Data = ",doc.data());
          this.navigation = doc.data().navigation;
          this.currentPage = doc.data().navigation[0].pageId;
          document.title = doc.data().title;
          this.contacts = doc.data().contacts;
      } else {
          // doc.data() will be undefined in this case
          console.log("No such corp!",this.corpId);
      }
    })
    .catch(error => {
      console.log("ref error",error)
    })
  }
}
</script>
