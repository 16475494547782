<template>
	<v-layout row>
		<v-flex xs3>
		<v-btn-toggle v-model="value.type" multiple>
      <v-btn flat value="font-weight-bold">
        <v-icon>format_bold</v-icon>
      </v-btn>
      <v-btn flat value="font-italic">
        <v-icon>format_italic</v-icon>
      </v-btn>
		</v-btn-toggle>
		</v-flex>
		<v-flex xs5>
		<v-select
			v-model="value.textSize"
			:items="sizes"
			label="Text size"
		></v-select>
		</v-flex>
		<v-flex xs4>
		<v-btn fab :dark="value.colour=='black'" :color="value.colour+' '+value.colourAttribute" v-on:click="showColours=!showColours">
			<v-icon>text_fields</v-icon>
		</v-btn>
		<v-select
			v-show="showColours"
			v-model="value.colour"
			:items="colours"
			label="Text colour"
			v-on:change="showColours=false"
		></v-select>
		<v-select
			v-show="showColours"
			v-model="value.colourAttribute"
			:items="colourAttributes"
			label="Attribute"
			v-on:change="showColours=false"
		></v-select>
		<v-btn-toggle v-model="value.align">
      <v-btn flat value="text-md-left">
        <v-icon>format_align_left</v-icon>
      </v-btn>
			<v-btn flat value="text-md-center">
        <v-icon>format_align_center</v-icon>
      </v-btn>
			<v-btn flat value="text-md-right">
        <v-icon>format_align_right</v-icon>
      </v-btn>
		</v-btn-toggle>
	</v-flex>
	</v-layout>
</template>
<script>
import defaults from "../components/defaults.js";
export default {
		name: 'fontType',
		props: {
	    value: {
				type: Object,
				required: true
			}
	  },
		data() {
			return {
				colours: defaults.colours,
				colourAttributes: defaults.colourAttributes,
				showColours: false,
				sizes: [
					{ text: "caption" },
					{ text: "body-1" },
					{ text: "body-2" },
					{ text: "subheading" },
					{ text: "title" },
					{ text: "headline" },
					{ text: "display-1" },
					{ text: "display-2" },
					{ text: "display-3" },
					{ text: "display-4" }
				]
			}
		}
  }
  </script>
